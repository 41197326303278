import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PaginatedList } from '@app/models/common/paginated-list.class';
import { PatchProduct, Product, RelevantProduct } from '@app/models/price/product.class';
import { Observable } from 'rxjs';
import { ListPrice, PostListPrice } from '@app/models/price/listPrice.class';
import { PriceDeviation } from '@app/models/price/priceDeviation.class';
import { ManualPricing } from '@app/models/price/manualPricing.class';
import { Price } from '@app/models/price/price.class';
import { PriceConfiguration, PriceConfigurationExisting } from '@app/models/price/priceConfiguration.class';
import { PriceLog } from '@app/models/price/priceCalculationStep.class';
import { PriceStatus } from '@app/models/price/priceStatus.enum';
import { PriceOverview } from '@app/models/price/priceOverview.class';
import { PostPriceLift, PriceLift } from '@app/models/price/priceLift.class';
import { PriceLiftStatusEnum } from '@app/models/price/priceLiftStatus.enum';
import { CompetitorChain, CompetitorChainGroup, CompetitorStation, CompetitorStationCreate } from '@app/models/price/priceCompetitors.class';
import { PriceHistory, PriceTriggerType } from '@app/models/price/priceHistory.class';
import { LowestPriceInRegion, PriceObservation, PriceObservationsPost } from '@app/models/price/priceObservation.class';
import { InfluenceRegion } from '@app/models/price/influenceRegion.class';
import { ConceptDistancePost, GraduationPost, ListPriceDeltaPost, PriceRule, ProductDeltaPost } from '@app/models/price/priceRule.class';
import { PriceRuleType } from '@app/models/price/priceRuleType.enum';
import { PriceObservationStatus } from '@app/models/price/priceObservationStatus.enum';
import { StationDetails } from '@app/models/price/stationDetails.class';
import { PriceObservationInfluences } from '@app/models/price/priceObservationInfluences.class';
import { ObservationRoute, PostObservationRoute } from '@app/models/price/observationRoute.class';
import { ListPriceType } from '@app/models/price/pricingType.enum';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  private uri = `${environment.routes.apiEndpoint}/price`;

  constructor(private http: HttpClient) { }

  //GET prices
  public getPrices(
    stationIds: string[] | null = null, stationGroupIds: string[] | null = null, productIds: string[] | null = null,
    priceStatuses: PriceStatus[] | null = null,
    pageSize = 10, pageIndex = 0,
    sortField = 'station.name', sortDescending = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;

    if (stationIds && stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds && stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    if (productIds && productIds.length) filters += `&ProductIds=${productIds.join('&ProductIds=')}`;
    if (priceStatuses && priceStatuses.length) filters += `&Statuses=${priceStatuses.join('&Statuses=')}`;
    return this.http.get<PaginatedList<Price>>(`${this.uri}/prices?${filters}`);
  }

  //GET Price logs
  public getPriceLogs(priceId: string[]) {
    const filter = `PriceIds=${priceId.join('&PriceIds=')}`;
    return this.http.get<PaginatedList<PriceLog>>(`${this.uri}/prices/log?${filter}`);
  }

  //GET Price overview
  public getPriceOverview(
    stationIds: string[] = [], stationGroupIds: string[] = [], influenceRegionIds: string[] = [],
    priceStatuses: PriceStatus[] = [],
    sortField = 'lastUpdated', sortDescending = true
  ) {
    let filters = '';
    filters += `SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    if (influenceRegionIds.length) filters += `&InfluenceRegionIds=${influenceRegionIds.join('&InfluenceRegionIds=')}`;
    if (priceStatuses.length) filters += `&Statuses=${priceStatuses.join('&Statuses=')}`;

    return this.http.get<PaginatedList<PriceOverview>>(`${this.uri}/prices/overview?${filters}`);
  }

  //GET price history
  public getPriceHistory(stationIds: string[] = [], stationGroupIds: string[] = [], influenceRegionIds: string[] = [],
    priceStatuses: PriceStatus[] = [], triggerTypes: PriceTriggerType[] = [],
    startDate: Date | null = null, endDate: Date | null = null,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string | null = null, sortDescending = true) {

    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortDescending=${sortDescending}`;
    if (sortField) filters += `&SortByField=${sortField}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    if (influenceRegionIds.length) filters += `&InfluenceRegionIds=${influenceRegionIds.join('&InfluenceRegionIds=')}`;
    if (priceStatuses.length) filters += `&Statuses=${priceStatuses.join('&Statuses=')}`;
    if (triggerTypes.length) filters += `&priceTriggerTypes=${triggerTypes.join('&priceTriggerTypes=')}`;
    if (startDate) filters += `&ModifiedAfter=${startDate.toISOString()}`;
    if (endDate) filters += `&ModifiedBefore=${endDate.toISOString()}`;

    return this.http.get<PaginatedList<PriceHistory>>(`${this.uri}/prices/history?${filters}`);
  }

  //GET price deviations
  public getPriceDeviations(
    stationIds: string[] | null = null,
    pageSize = 10, pageIndex = 0,
    sortField = 'station.name', sortDescending = true,
    activeFrom: Date | null = null, activeTo: Date | null = null
  ) {
    let filters = '';
    if (stationIds && stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (activeFrom) filters += `&ActiveFrom=${activeFrom.toISOString()}`;
    if (activeTo) filters += `&ActiveTo=${activeTo.toISOString()}`;

    return this.http.get<PaginatedList<PriceDeviation>>(`${this.uri}/priceDeviations?${filters}&PageSize=${pageSize}&PageIndex=${pageIndex + 1}&SortByField=${sortField}&SortDescending=${sortDescending}`);
  }

  //GET manual prices
  public getManualPrices() {
    return this.http.get(`${this.uri}/manualPrices`);
  }

  //POST manual prices
  public postManualPrices(manualPricing: ManualPricing) {
    return this.http.post(`${this.uri}/manualPrices`, manualPricing);
  }

  //GET Price lifts
  public getPriceLifts(statuses: PriceLiftStatusEnum[] = [], stationGroupIds: string[] = [], shouldPublishAtBefore: Date | null = null, shouldPublishAtAfter: Date | null = null, pageIndex = 0, pageSize = 10, sortField = 'ShouldPublishAt', sortDescending = true): Observable<PaginatedList<PriceLift>> {
    let url = `${this.uri}/priceLifts?`
      + `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`
      + `&SortByField=${sortField}&SortDescending=${sortDescending}`;

    statuses.forEach(status => {
      url += `&Statuses=${status}`;
    });
    stationGroupIds.forEach(groupId => {
      url += `&StationGroupIds=${groupId}`;
    });
    if (shouldPublishAtBefore) url += `&ShouldPublishAtBefore=${shouldPublishAtBefore.toISOString()}`;
    if (shouldPublishAtAfter) url += `&ShouldPublishAtAfter=${shouldPublishAtAfter.toISOString()}`;

    return this.http.get<PaginatedList<PriceLift>>(url);
  }

  //POST Price lifts
  public postPriceLift(priceLift: PostPriceLift) {
    return this.http.post(`${this.uri}/priceLifts`, priceLift);
  }

  //PUT Price lifts
  public putPriceLift(priceLiftId: string, priceLift: PostPriceLift) {
    return this.http.put(`${this.uri}/priceLifts/${priceLiftId}`, priceLift);
  }

  //DELETE Price lifts
  public deletePriceLift(priceLiftId: string) {
    return this.http.delete(`${this.uri}/priceLifts/${priceLiftId}`);
  }

  //POST Price lifts rerun
  public rerunPriceLift(priceLiftId: string) {
    return this.http.post(`${this.uri}/priceLifts/${priceLiftId}/republish`, null);
  }

  //POST Rerun of single price
  public rerunSinglePrice(priceId: string) {
    return this.http.post(`${this.uri}/prices/${priceId}/republish`, null);
  }

  //GET listprices
  public getListPrices(
    productIds: string[] = [],
    pageSize = 100, pageIndex = 0,
    sortField = 'Product.Name', sortDescending = true,
    includeHistorical = false, stationTypes: ListPriceType[] = []): Observable<PaginatedList<ListPrice>> {
    let url = `${this.uri}/listPrices?`
      + `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`
      + `&SortByField=${sortField}&SortDescending=${sortDescending}`
      + `&IncludeHistorical=${includeHistorical}`;
    if (productIds.length > 0) {
      url += `&ProductIds=${productIds.join(',')}`;
    }
    if (stationTypes.length > 0) {
      url += `&StationTypes=${stationTypes.join(',')}`;
    }
    return this.http.get<PaginatedList<ListPrice>>(url);
  }

  //POST listprices
  public postListPrices(listPrices: PostListPrice[]) {
    const body = {
      products: listPrices
    };
    return this.http.post(`${this.uri}/listPrices`, body);
  }

  //PATCH products
  public patchProducts(changedProducts: PatchProduct[]) {
    return this.http.patch(`${this.uri}/products/batch`, { products: changedProducts });
  }

  //GET products
  public getProducts(
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'grade', sortDescending: boolean = true
  ): Observable<PaginatedList<Product>> {
    const url = `${this.uri}/products?`
      + `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`
      + `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    return this.http.get<PaginatedList<Product>>(url);
  }

  //GET Relevant products
  public getRelevantProducts(stationId: string): Observable<RelevantProduct> {
    return this.http.get<RelevantProduct>(`${this.uri}/stations/${stationId}/relevantProducts`);
  }

  //GET Configurations
  public getConfigurations(pageSize = 1, pageIndex = 0) {
    return this.http.get<PaginatedList<PriceConfigurationExisting>>(`${this.uri}/priceConfigurations?PageSize=${pageSize}&PageIndex=${pageIndex + 1}`);
  }

  //POST Configurations
  public postConfigurations(configuration: PriceConfiguration) {
    return this.http.post<PriceConfiguration>(`${this.uri}/priceConfigurations`, configuration);
  }

  //POST Price request
  public postPriceRequest(stationId: string) {
    return this.http.post(`${this.uri}/stations/${stationId}/prices/request`, null);
  }

  // GET Competitors
  public getCompetitorStations(
    connectedToInfluenceRegion: boolean | null = null,
    name: string | null = null,
    pageSize: number = 2000, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = false
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (connectedToInfluenceRegion !== null)
      filters += `&IsConnectedToInfluenceRegion=${connectedToInfluenceRegion}`;
    if (name) filters += `&Name=${name}`;
    return this.http.get<PaginatedList<CompetitorStation>>(`${this.uri}/competitors/stations?${filters}`);
  }

  // POST Competitors
  public createCompetitorStation(data: CompetitorStationCreate) {
    return this.http.post(`${this.uri}/competitors/stations`, data);
  }

  // PUT Competitors
  public updateCompetitorStation(id: string, data: CompetitorStationCreate) {
    return this.http.put(`${this.uri}/competitors/stations/${id}`, data);
  }

  // DELETE Competitors
  public deleteCompetitorStation(id: string) {
    return this.http.delete(`${this.uri}/competitors/stations/${id}`);
  }

  // GET Competitor Chains
  public getCompetitorChains(
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    return this.http.get<PaginatedList<CompetitorChain>>(`${this.uri}/competitors/chains?${filters}`);
  }

  // POST Competitor Chains
  public createCompetitorChain(data: { name: string }) {
    return this.http.post(`${this.uri}/competitors/chains`, data);
  }

  // PUT Competitor Chains
  public updateCompetitorChain(id: string, data: { name: string }) {
    return this.http.put(`${this.uri}/competitors/chains/${id}`, data);
  }

  // DELETE Competitor Chains
  public deleteCompetitorChain(id: string) {
    return this.http.delete(`${this.uri}/competitors/chains/${id}`);
  }

  // GET Competitor Chain Groups
  public getCompetitorChainGroups(
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    return this.http.get<PaginatedList<CompetitorChainGroup>>(`${this.uri}/competitors/chains/groups?${filters}`);
  }

  // POST Competitor Chain Groups
  public createCompetitorChainGroup(data: { name: string, competitorChains: string[], id?: string, comment?: string }) {
    return this.http.post(`${this.uri}/competitors/chains/groups`, data);
  }

  // DELETE Competitor Chain Groups
  public deleteCompetitorChainGroup(id: string) {
    return this.http.delete(`${this.uri}/competitors/chains/groups/${id}`);
  }

  // GET Price Observations
  public getPriceObservations(
    competitorStationIds: string[] = [], influenceRegionIds: string[] = [],
    userIds: string[] = [],
    statuses: PriceObservationStatus[] = [],
    startDate: Date | null, endDate: Date | null,
    priceFrom?: number, priceTo?: number,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'observedAt', sortDescending: boolean = true,
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (competitorStationIds.length)
      filters += `&CompetitorStationIds=${competitorStationIds.join('&CompetitorStationIds=')}`;
    if (influenceRegionIds.length)
      filters += `&InfluenceRegionIds=${influenceRegionIds.join('&InfluenceRegionIds=')}`;
    if (userIds.length)
      filters += `&UserIds=${userIds.join('&UserIds=')}`;
    if (priceFrom)
      filters += `&PriceFrom=${priceFrom}`;
    if (priceTo)
      filters += `&PriceTo=${priceTo}`;
    if (statuses.length)
      filters += `&Statuses=${statuses.join('&Statuses=')}`;
    if (startDate)
      filters += `&ObservedAtFrom=${startDate.toISOString()}`;
    if (endDate)
      filters += `&ObservedAtTo=${endDate.toISOString()}`;

    return this.http.get<PaginatedList<PriceObservation>>(`${this.uri}/priceObservations?${filters}`);
  }

  // POST Price Observations
  public postPriceObservations(data: PriceObservationsPost) {
    return this.http.post(`${this.uri}/priceObservations`, data);
  }

  //GET Price observation details
  public getPriceObservationDetails(id: string) {
    return this.http.get<PriceObservationInfluences>(`${this.uri}/priceObservations/${id}/details`);
  }

  //GET Observation routes for current user
  public getObservationRoutes(
    pageSize: number = 1000, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;

    return this.http.get<PaginatedList<ObservationRoute>>(`${this.uri}/observationRoutes?${filters}`);
  }

  //GET Observation route by ID
  public getObservationRoute(id: string) {
    return this.http.get<ObservationRoute>(`${this.uri}/observationRoutes/${id}`);
  }

  //POST Observation route
  public postObservationRoute(route: PostObservationRoute) {
    return this.http.post(`${this.uri}/observationRoutes`, route);
  }

  //PUT Observation route
  public putObservationRoute(id: string, route: PostObservationRoute) {
    return this.http.put(`${this.uri}/observationRoutes/${id}`, route);
  }

  //DELETE Observation route
  public deleteObservationRoute(id: string) {
    return this.http.delete(`${this.uri}/observationRoutes/${id}`);
  }

  public getLowestPriceInInfluenceRegion(competitorStationId: string): Observable<LowestPriceInRegion[]> {
    const filters = `CompetitorStationIds=${competitorStationId}`;
    return this.http.get<LowestPriceInRegion[]>(`${this.uri}/prices/influenceRegion/lowestPrice?${filters}`);
  }

  // GET Influence Regions
  public getInfluenceRegions(
    stationIds: string[] = [], competitorStationIds: string[] = [],
    name: string | null = null,
    pageSize: number = 1000, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (stationIds.length)
      filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (competitorStationIds.length)
      filters += `&CompetitorStationIds=${competitorStationIds.join('&CompetitorStationIds=')}`;
    if (name) filters += `&Name=${name}`;

    return this.http.get<PaginatedList<InfluenceRegion>>(`${this.uri}/influenceRegions?${filters}`);
  }

  // CREATE Influence Regions
  public createInfluenceRegion(data: { name: string, comment: string, stationIds: string[], competitorStationIds: string[] }) {
    return this.http.post(`${this.uri}/influenceRegions`, data);
  }

  // PUT Influence Regions
  public updateInfluenceRegion(id: string, data: { name: string, comment: string, stationIds: string[], competitorStationIds: string[] }) {
    return this.http.put(`${this.uri}/influenceRegions/${id}`, data);
  }

  // DELETE Influence Regions
  public deleteInfluenceRegion(id: string) {
    return this.http.delete(`${this.uri}/influenceRegions/${id}`);
  }

  //GET Price rules
  public getPriceRules(
    stationIds: string[] | null = null, stationGroupIds: string[] | null = null,
    influenceRegionIds: string[] | null = null,
    name: string | null = null,
    isActive: boolean | null = null,
    priceRuleType: PriceRuleType | null = null,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = true
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    if (priceRuleType) filters += `&Type=${priceRuleType}`;
    if (isActive !== null) filters += `&IsActive=${isActive}`;
    if (stationIds && stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds && stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    if (influenceRegionIds && influenceRegionIds.length) filters += `&InfluenceRegionIds=${influenceRegionIds.join('&InfluenceRegionIds=')}`;
    if (name) filters += `&Name=${name}`;

    return this.http.get<PaginatedList<PriceRule>>(`${this.uri}/priceRules?${filters}`);
  }

  //GET Archived Price rules
  public getArchivedPriceRules(
    priceRuleId: string,
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'name', sortDescending: boolean = true
  ): Observable<PaginatedList<PriceRule>> {
    let filters = '';
    filters += `PriceRuleReferences=${priceRuleId}`;
    filters += `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    filters += `&SortByField=${sortField}&SortDescending=${sortDescending}`;
    return this.http.get<PaginatedList<PriceRule>>(`${this.uri}/priceRules/archive?${filters}`);
  }

  //POST Concept distance rule
  public postConceptDistanceRule(requestBody: ConceptDistancePost) {
    return this.http.post(`${this.uri}/priceRules/conceptDistance`, requestBody);
  }

  //PUT List price delta rule
  public postListPriceDeltaRule(requestBody: ListPriceDeltaPost) {
    return this.http.post(`${this.uri}/priceRules/listPriceDelta`, requestBody);
  }

  //POST Graduation rule
  public postGraduationRule(requestBody: GraduationPost) {
    return this.http.post(`${this.uri}/priceRules/graduation`, requestBody);
  }

  //POST Product delta rule
  public postProductDeltaRule(requestBody: ProductDeltaPost) {
    return this.http.post(`${this.uri}/priceRules/productDelta`, requestBody);
  }

  //PUT Concept distance rule
  public putConceptDistanceRule(ruleId: string, requestBody: ConceptDistancePost) {
    return this.http.put(`${this.uri}/priceRules/conceptDistance/${ruleId}`, requestBody);
  }

  //PUT List price delta rule
  public putListPriceDeltaRule(ruleId: string, requestBody: ListPriceDeltaPost) {
    return this.http.put(`${this.uri}/priceRules/listPriceDelta/${ruleId}`, requestBody);
  }

  //PUT Graduation rule
  public putGraduationRule(ruleId: string, requestBody: GraduationPost) {
    return this.http.put(`${this.uri}/priceRules/graduation/${ruleId}`, requestBody);
  }

  //PUT Product delta rule
  public putProductDeltaRule(ruleId: string, requestBody: ProductDeltaPost) {
    return this.http.put(`${this.uri}/priceRules/productDelta/${ruleId}`, requestBody);
  }

  // Get station details
  public getStationDetails(stationId: string) {
    return this.http.get<StationDetails>(`${this.uri}/prices/priceDetailsPerStation/${stationId}`);
  }
}
